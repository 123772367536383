.resources-list {
  &--blur {
    filter: blur(4px);
    user-select: none;
    pointer-events: none;
    cursor: default;
  }

  .product-card {
    text-align: left;
    width: 100%;
    max-width: 350px;
    display: none;
    align-items: flex-start;
    position: relative;
    justify-self: center;

    &--visible {
      display: flex;
    }

    &__link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &__grid {
      gap: 20px;

      @include mq('large') {
        margin-left: calc(-1 * var(--emu-semantic-spacing-horizontal-narrow));
        margin-right: calc(-1 * var(--emu-semantic-spacing-horizontal-narrow));
      }
    }

    &__image {
      img {
        width: 100%;
        height: 232px;
        object-fit: cover;
      }
    }

    &__pretitle {
      width: 100%;
    }

    &__title {
      width: 100%;
    }

    &__button.emu-button-v2.emu-button {
      text-decoration: none;
      gap: var(--emu-common-spacing-small);

      &.product-card__button {
        &--view,
        &--download,
        &--order {
          &::after {
            content: '';
            height: 12px;
            width: 12px;
            background-size: contain;
            background-repeat: no-repeat;
            display: inline-flex;
          }
        }

        &--view {
          &::after {
            background-image: url('../../assets/images/view.png');
          }
        }

        &--download {
          &::after {
            background-image: url('../../assets/images/download.png');
          }
        }

        &--order {
          &::after {
            background-image: url('../../assets/images/order.png');
          }
        }
      }
    }

    &__button-container {
      margin-top: auto;
    }

    &__pagination {
      .first-btn,
      .last-btn,
      .page-counter {
        display: none;
      }
    }
  }
}

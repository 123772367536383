@import './utils/g-typography-utils';
@import './utils/g-container-utils';
@import './utils/g-spacing-utils';
@import './utils/g-color-utils';
@import './utils/g-button-utils';
@import './utils/g-carousel-utils';
@import './utils/g-teaser-utils';
@import './utils/g-modal-utils';
@import './utils/g-header-utils';
@import './utils/g-letter-spacing-utils';
@import './utils/g-image-utils';
@import './utils/g-width-utils';
@import './utils/g-separator-utils';

// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }

  @include aem-editor-view {
    display: initial !important;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }

  @include aem-editor-view {
    display: initial !important;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }

  @include aem-editor-view {
    display: initial !important;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }

  @include aem-editor-view {
    display: initial !important;
  }
}

// padding adjustments
.no-pad {
  padding: var(--emu-common-spacing-none);
}

.no-x-pad {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

.no-y-pad {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
}

// anchor util to remove text decoration
.u-text-decoration--none a {
  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none !important;
  }
}

// anchor utils to show anchor only when hovered
.u-text-decoration--on-hover a {
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
}

// teaser that is to be overlapped with the next content
.teaser:has(.u-teaser--overlapped-content) {
  @include mq('large') {
    margin-bottom: -140px;
    z-index: var(--emu-common-other-z-index-base);
  }

  & + * {
    position: relative;
    z-index: var(--emu-common-other-z-index-layer);
  }
}

// hides overflow in the mobile devices when the mobile menu is open
.mobile-menu-open {
  @include mq('1023px', 'max-width') {
    position: fixed;
    top: var(--scrollTopPos, 0);
    inline-size: 100%;
  }
}

// hides for all users
.u-hide {
  display: none !important;

  @include aem-editor-view {
    display: block !important;
  }
}

// util for spaced paragraphs in a text component - 8px (in desktop) and 16px (in mobile) 
.u-spaced-paragraphs--sm {
  p {
    margin-bottom: var(--emu-common-spacing-medium);

    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-small);
    }

    &:last-child {
      margin-bottom: var(--emu-common-spacing-none);
    }
  }
}

// util for spaced paragraphs in a text component - 22px in desktop and mobile
.u-spaced-paragraphs {
  p {
    margin-bottom: var(--emu-semantic-spacing-vertical-narrow);  

    &:last-child {
      margin-bottom: var(--emu-common-spacing-none);
    }
  }
}

// util flex with gap
.u-flex {
  display: flex;
  gap: var(--emu-semantic-spacing-horizontal-narrow);
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  &--center-align {
    justify-content: center;
  }

  &--right-align {
    justify-content: flex-end;
  }
}

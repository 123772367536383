.aaaem-carousel {
  // hides liveregion, adds carousel arrows on either sides of the slide
  &.u-carousel--regular {
    .tns-liveregion {
      display: none;
    }

    .aaaem-carousel {
      &__actions {
        display: block;
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        padding: var(--emu-common-spacing-none);
      }

      &__action {
        position: absolute;
        top: 0;
        background-color: var(--emu-common-colors-transparent);
        height: var(--emu-common-sizing-small);

        &-prev {
          transform: translateX(-100%) translateY(-50%) translateY(-8px);

          @include mq('large') {
            transform: translateX(-100%)
              translateX(
                calc(-1 * var(--emu-semantic-spacing-horizontal-narrow))
              )
              translateY(-50%) translateY(-8px);
          }
        }

        &-next {
          transform: translateX(100%) translateY(-50%) translateY(-8px);

          @include mq('large') {
            transform: translateX(100%)
              translateX(var(--emu-semantic-spacing-horizontal-narrow))
              translateY(-50%) translateY(-8px);
          }
        }
      }
    }
  }

  // hides liveregion, arrows, indicators, and centers the content
  &.u-carousel--disabled.emu-carousel {
    .tns-liveregion {
      display: none;
    }

    .tns-inner {
      margin: var(--emu-common-spacing-none);
      display: flex;
      justify-content: center;
    }

    .aaaem-carousel {
      &__actions,
      &__indicators {
        display: none;
      }
    }
  }

  // hides arrows only in mobile view
  &.u-carousel--hide-arrows-in-mobile.emu-carousel {
    @include mq('1023px', 'max-width') {
      .aaaem-carousel {
        &__actions {
          display: none;
        }
      }
    }
  }

  // vertically aligns the content to the center
  &.u-carousel--vertical-center-content {
    .aaaem-carousel__content {
      display: flex;
      align-items: center;
    }
  }
}

.u-carousel-title {
  margin-bottom: -18px; // height of the carousel count and arrows
  max-width: 40%;

  @include mq('large') {
    max-width: 50%;
  }

  @include aem-editor-view {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

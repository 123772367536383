.u-bg--white {
  background-color: var(--emu-common-colors-white);
}

.u-bg--black {
  background-color: var(--emu-common-colors-black);
}

.u-bg--grey-1000 {
  background-color: var(--emu-common-colors-grey-1000);
}

.u-bg--brown-500 {
  background-color: var(--emu-common-colors-light-brown-500);
}

.u-bg--dark-brown-500 {
  background-color: var(--emu-common-colors-dark-brown-500);
}

.u-bg--medium-brown-100 {
  background-color: var(--emu-common-colors-medium-brown-100);
}

.u-bg--light-brown-50 {
  background-color: var(--emu-common-colors-light-brown-50);
}

.u-bg--light-brown-100 {
  background-color: var(--emu-common-colors-light-brown-100);
}

.u-bg--light-grey-100 {
  background-color: var(--emu-common-colors-light-grey-100);
}

.u-bg--light-grey-500 {
  background-color: var(--emu-common-colors-light-grey-500);
}

.u-bg--light-brown-200 {
  background-color: var(--emu-common-colors-light-brown-200);
}

.u-bg--blush-100 {
  background-color: var(--emu-common-colors-blush-100);
}

.u-bg--common-grey-100 {
  background-color: var(--emu-common-colors-grey-100);
}

.u-bg--beige-100 {
  background-color: var(--emu-common-colors-beige-100);
}

.u-text--white {
  color: var(--emu-common-colors-white);

  // specificity needed to add some overrides
  &.emu-navigation {
    > ul > li {
      > a,
      > span {
        &,
        &:hover,
        &:focus,
        &:active {
          color: var(--emu-common-colors-white);
        }
      }
    }
  }
}

.u-text--black {
  color: var(--emu-common-colors-black);
}

.u-text--grey-1000 {
  color: var(--emu-common-colors-grey-1000);
}

.u-text--brown-500 {
  color: var(--emu-common-colors-light-brown-500);
}

.u-text--dark-brown-500 {
  color: var(--emu-common-colors-dark-brown-500);
}

.u-text--medium-brown-100 {
  color: var(--emu-common-colors-medium-brown-100);
}

.u-text--medium-brown-500 {
  color: var(--emu-common-colors-medium-brown-500);
}

.u-text--light-brown-50 {
  color: var(--emu-common-colors-light-brown-50);
}

.u-text--light-brown-100 {
  color: var(--emu-common-colors-light-brown-100);
}

.u-text--light-grey-100 {
  color: var(--emu-common-colors-light-grey-100);
}

.u-text--error {
  color: var(--emu-semantic-colors-error-light);
}

[class*='u-text--'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  a,
  span,
  button.btn--as-link {
    color: inherit !important;
  }
}

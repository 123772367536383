.contact-us {
  @include mq('large') {
    display: flex;
    gap: calc(1.5 * var(--emu-semantic-spacing-horizontal-wide));
    justify-content: center;
  }

  @include aem-editor-view {
    display: block !important;
  }

  > .container {
    @include aem-editor-view {
      margin-bottom: var(--emu-semantic-spacing-horizontal-wide);
    }

    @include mq('large') {
      max-width: 380px; // needed for layout
    }

    &:first-child {
      @include mq('large') {
        .contact-us {
          &__section {
            padding-left: var(--emu-common-spacing-none);
            border-left: none;
          }
        }
      }
    }
  }

  &__section {
    border-left: var(--emu-common-border-width-thin) solid
      var(--emu-common-colors-medium-brown-500);
    padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  }

  &__text {
    a {
      display: inline-block;
      word-break: break-all;
    }
  }
}

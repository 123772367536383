.button {
  // disabled button
  &:has(.btn--disabled),
  &:has(a:disabled),
  &:has(button:disabled) {
    opacity: 0.5;
    transition: all 0.2s;
    cursor: not-allowed;

    &:hover {
      opacity: 0.25;
    }
  }

  .btn--disabled,
  a:disabled,
  button:disabled {
    pointer-events: none;
  }

  // profile icon in the header, mobile menu hamburger and mobile menu close icons
  .btn--icon {
    // specificity is required to override the default styles
    &.emu-button-v2.emu-button.emu-button {
      &,
      &:active,
      &:hover,
      &:focus {
        padding: var(--emu-common-spacing-none);
        background-color: var(--emu-common-colors-transparent);
        color: var(--emu-common-colors-transparent);
        outline: var(--emu-common-outline-width-unset);
        border: var(--emu-common-border-width-none);
        transition: none;
      }

      span {
        display: none;
      }
    }
  }

  // profile icon in the header
  .btn--icon-white-bg {
    // specificity is required to override the default styles
    &.emu-button-v2.emu-button.emu-button.btn--icon {
      border-radius: var(
        --emu-component-actions-button-border-radius-primary-outline
      );
      padding: var(--emu-common-spacing-small);
      background-color: var(--emu-common-colors-white);

      img {
        width: var(--emu-common-sizing-small);
      }
    }
  }

  // Buttons in brand showcase section
  .btn--as-link {
    &.emu-button-v2.emu-button {
      &,
      &:active,
      &:hover,
      &:focus {
        font-family: var(--emu-semantic-font-families-heading);
        padding: var(--emu-common-spacing-none);
        background-color: var(--emu-common-colors-transparent);
        color: var(--emu-common-colors-black);
        outline: var(--emu-common-outline-width-unset);
        border: var(--emu-common-border-width-none);
        transition: none;
        text-decoration: underline;
      }
    }
  }

  .btn--close {
    width: 14px; // by design
    height: 14px; // by design
    overflow: hidden;
    position: relative;

    &.emu-button-v2.emu-button {
      display: block;
    }

    span,
    svg {
      display: none;
    }

    &::before,
    &::after {
      content: '';
      width: var(--emu-common-border-width-thin);
      height: 150%;
      background-color: var(--emu-common-colors-black);
      transform: translate(-50%, -50%) rotate(45deg);
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

// Buttons for cards
.btn-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  > .button {
    &:not(:last-child) {
      .emu-button {
        margin-right: var(--emu-semantic-spacing-horizontal-narrow);
      }
    }
  }

  &--left-align {
    justify-content: flex-start;
  }

  &--right-align {
    justify-content: flex-end;
  }
}

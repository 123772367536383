.plyr.plyr--video {
  --plyr-color-main: var(--emu-common-colors-dark-brown-700);
  --plyr-video-control-color: var(--emu-common-colors-black);
  --plyr-video-controls-background: var(--emu-common-colors-white);
  --plyr-video-range-track-background: var(--emu-common-colors-light-brown-50);
  --plyr-range-track-height: calc(3 * var(--emu-common-sizing-xxs));
  --plyr-control-radius: calc(0.5 * var(--emu-common-border-radius-xxs));
  --plyr-control-icon-size: var(--emu-common-sizing-small);
  --plyr-control-spacing: var(--emu-common-spacing-small);
  --plyr-video-control-color-hover: var(--emu-common-colors-black);
  --plyr-video-control-background-hover: var(--emu-common-colors-transparent);
  --plyr-range-thumb-height: calc(0.3 * var(--emu-common-sizing-small));
  --plyr-range-thumb-shadow: var(
    --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one
  );
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s;
    background-size: cover;
    background-image: url('../../assets/images/spinner.gif');
  }

  &.plyr--loading {
    &::after {
      opacity: 1;
    }
  }

  &.plyr--playing {
    button.plyr__control.plyr__control--overlaid[data-plyr='play'] {
      opacity: 0;
      pointer-events: none;
    }

    .plyr__controls {
      opacity: 1;
      pointer-events: all;
    }
  }

  .plyr__controls {
    opacity: 0;
    pointer-events: none;
  }

  button.plyr__control[data-plyr='play'] {
    background-color: var(--emu-common-colors-white);
    opacity: 1; // As per figma design

    &.plyr__control--overlaid {
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .plyr {
    &__controls {
      padding-left: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-medium);
      padding-top: var(--emu-semantic-spacing-half-line-height-narrow);
      padding-bottom: var(--emu-semantic-spacing-half-line-height-narrow);
      border-radius: var(--emu-semantic-border-radius-medium);
      max-width: min(87%, 289px); // As per figma design
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 19px; // As per figma design

      @include mq('large') {
        padding-left: var(--emu-semantic-spacing-horizontal-narrow);
        padding-right: var(--emu-semantic-spacing-horizontal-narrow);
        max-width: 393px; // As per figma design
      }

      button.plyr__control[data-plyr='play'] {
        padding: var(--emu-common-spacing-small);
        border: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-black);
        border-radius: 100%;
        margin-right: calc(0.5 * var(--emu-semantic-spacing-horizontal-narrow));

        svg {
          width: var(--emu-common-sizing-xs);
          height: var(--emu-common-sizing-xs);
        }
      }

      &__item {
        &.plyr__progress__container {
          margin-right: var(--emu-common-spacing-small);
          border-radius: var(--emu-common-border-radius-xxs);

          @include mq('large') {
            margin-right: var(--emu-semantic-spacing-horizontal-wide);
          }
        }

        &.plyr__volume {
          button.plyr__control {
            padding: var(--emu-common-spacing-xs);
          }

          input[data-plyr='volume'] {
            margin-left: var(--emu-common-spacing-small);
            margin-right: var(--emu-common-spacing-medium);

            @include mq('large') {
              margin-right: var(--emu-common-spacing-none);
            }
          }
        }
      }

      button[data-plyr='fullscreen'] {
        padding: var(--emu-common-spacing-none);

        @include mq('large') {
          margin-left: var(--emu-semantic-spacing-vertical-narrow);
        }
      }
    }
  }

  &.plyr--fullscreen-fallback {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-height: 100vh;
    position: fixed;

    .plyr__controls {
      z-index: var(--emu-common-other-z-index-overlay);
    }
  }
}

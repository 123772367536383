.step-form {
  position: relative;

  &.u-step-form {
    &--showing-success {
      .step-form__el,
      .step-form__cta-container {
        display: none;
      }

      .step-form__msg--success {
        display: block;
      }
    }

    &--showing-error {
      .step-form__msg--success {
        display: none;
      }

      .step-form__msg--error {
        display: block;
      }
    }

    &--showing-submit {
      // needed to increase the specificity
      .emu-button.emu-button-v2.step-form__cta.step-form__cta--submit {
        display: inline-flex;
      }
    }

    &--showing-next {
      // needed to increase the specificity
      .emu-button.emu-button-v2.step-form__cta.step-form__cta--next {
        display: inline-flex;
      }
    }

    &--showing-prev {
      // needed to increase the specificity
      .emu-button.emu-button-v2.step-form__cta.step-form__cta--prev {
        display: inline-flex;
      }
    }

    &--progressive {
      .u-form-element--disabled {
        display: none;
      }

      .options {
        .step-form__selected-el {
          &.emu-form-radio {
            label.emu-form-radio__label {
              margin-bottom: var(--emu-common-spacing-none);

              &:not(.step-form__selected-el-form-label) {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &:not(.u-step-form--progressive) {
    .step-form__el {
      &:not(.step-form__el--show):not(.cq-Editable-dom--container &) {
        display: none;
      }
    }
  }

  &.u-step-form--progressive {
    .step-form__el {
      &:not(.step-form__el--show):not(.step-form__el--prev):not(
          .cq-Editable-dom--container &
        ) {
        display: none;
      }
    }
  }

  &__msg {
    display: none;

    &--error {
      border: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-error-light);
      color: var(--emu-semantic-colors-error-light);
    }
  }

  &__cta {
    &-container {
      // needed to increase the specificity
      .emu-button.emu-button-v2.step-form__cta {
        display: none;

        @include aem-editor-view {
          display: inline-flex !important;
        }
      }
    }
  }
}

.aaaem-carousel {
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: var(--emu-common-spacing-small);
    gap: 70px; // As per the figma design.
  }

  &__action {
    position: relative;
  }

  .tns-liveregion {
    position: absolute;
    left: unset;
    top: 1px; // As per the figma design.
    right: 34px; // As per the figma design.
    font: var(--emu-semantic-typography-narrow-body-xs);
    @include letterSpacing('xxl');
    text-transform: uppercase;

    &:not(.can-show) {
      display: none;
    }

    @include mq('large') {
      font: var(--emu-semantic-typography-wide-body-xs);
    }
  }
}

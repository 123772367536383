.ajaxContainer {
  form {
    margin-left: calc(-1 * var(--emu-semantic-spacing-horizontal-narrow));
    width: calc(100% + (2 * var(--emu-semantic-spacing-horizontal-narrow)));

    > div {
      padding-left: var(--emu-semantic-spacing-horizontal-narrow);
      padding-right: var(--emu-semantic-spacing-horizontal-narrow);
    }
  }

  .emu-form-checkbox,
  .emu-form-radio,
  .emu-form-text,
  .emu-form-textarea,
  .emu-form-dropdown,
  .input-with-custom-text {
    margin-top: var(--emu-common-spacing-none);
  }
}

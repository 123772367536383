// returns pre-defined breakpoint from map, or else custom value passed in
@function breakpoint($breakpoint) {
  @if (map-has-key(breakpoints(), $breakpoint)) {
    @return map-get(breakpoints(), $breakpoint);
  } @else {
    @return $breakpoint;
  }
}

@mixin mq($breakpoint, $respond: 'min-width') {
  @media (#{$respond}: breakpoint($breakpoint)) {
    @content;
  }
}

.article {
  &__grid {
    display: grid;
    justify-content: center;
    gap: var(--emu-common-spacing-medium);
    padding-top: var(--emu-common-spacing-none);
    grid-template-columns: repeat(auto-fit, minmax(331px, 1fr));

    @include mq('medium') {
      grid-template-columns: repeat(2, 255px);
    }

    @include mq('large') {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__card {
    width: 100%;
		max-width: 331px;
    justify-self: center;
    display: none;
    position: relative;

    &--visible {
      display: block;
    }
  }

  &__link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__image {
    img {
      width: 100%;
      height: 190px;
      object-fit: cover;
      display: block;
    }

    &--restricted {
      position: relative;
      z-index: var(--emu-common-other-z-index-base);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        background-image: url('../../assets/images/lock.png');
        background-repeat: no-repeat;
        background-position: 19px 19px;
        pointer-events: none;
        z-index: var(--emu-common-other-z-index-overlay);
      }
    }
  }
}

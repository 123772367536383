[data-component='modal'] {
  .modal {
    &-wrapper {
      display: block;
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transition: all var(--emu-common-other-time-transition-short) linear;
      transition-delay: var(--emu-common-other-time-transition-short);
      align-content: center;

      @include aem-editor-view {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
        pointer-events: initial;
        max-height: none;
        height: auto;
        inset: unset;
        align-content: initial;
      }

      .modal-content {
        transform: translateY(-80px);
        transition: all var(--emu-common-other-time-transition-short) linear;
        transition-delay: 0s;
        opacity: 0;

        @include aem-editor-view {
          transform: translateY(0);
          transition-delay: 0s;
          opacity: 1;
        }
      }

      &.is-open {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
        pointer-events: initial;

        .modal-content {
          transform: translateY(0);
          transition-delay: var(--emu-common-other-time-transition-short);
          opacity: 1;
        }
      }
    }

    // default modal overrides
    &-content {
      margin-top: 100px; // position from where the modals starts. Modal has 15% top and bottom margin without tokens from aaaem-common
      margin-bottom: 100px;
      padding: var(--emu-component-layers-modal-body-padding-top-narrow)
        var(--emu-component-layers-modal-body-padding-right-narrow)
        var(--emu-component-layers-modal-body-padding-bottom-narrow)
        var(--emu-component-layers-modal-body-padding-left-narrow);

      @include mq('large') {
        padding: var(--emu-component-layers-modal-body-padding-top-wide)
          var(--emu-component-layers-modal-body-padding-right-wide)
          var(--emu-component-layers-modal-body-padding-bottom-wide)
          var(--emu-component-layers-modal-body-padding-left-wide);
      }

      .modal__first-el,
      .emu-form__success-message > *:first-child {
        margin-right: calc(
          var(--emu-component-layers-modal-close-size-width-narrow) +
            var(--emu-common-spacing-small)
        ); // making sure that the first element does not cover the close icon

        @include mq('large') {
          margin-right: calc(
            var(--emu-component-layers-modal-close-size-width-wide) +
              var(--emu-common-spacing-small)
          ); // making sure that the first element does not cover the close icon
        }
      }

      // close icon built from pseudo element as the icon given by aaaem is not matching well
      .close {
        width: var(--emu-component-layers-modal-close-size-width-narrow);
        height: var(--emu-component-layers-modal-close-size-height-narrow);
        opacity: 1;
        overflow: hidden;
        top: var(
          --emu-component-layers-modal-body-padding-top-narrow
        ); // intentional padding value for logical reason
        right: var(
          --emu-component-layers-modal-body-padding-right-narrow
        ); // intentional padding value for logical reason

        @include mq('large') {
          top: var(
            --emu-component-layers-modal-body-padding-top-wide
          ); // intentional padding value for logical reason
          right: var(
            --emu-component-layers-modal-body-padding-right-wide
          ); // intentional padding value for logical reason
        }

        &.main-close {
          z-index: var(--emu-common-other-z-index-layer);
        }

        span {
          display: none;
        }

        &::before,
        &::after {
          content: '';
          width: var(--emu-common-border-width-thin);
          height: 150%;
          background-color: var(--emu-common-colors-black);
          transform: translate(-50%, -50%) rotate(45deg);
          position: absolute;
          top: 50%;
          left: 50%;
        }

        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    &-cta-container {
      justify-content: flex-start;

      @include mq('large') {
        margin-top: var(--emu-common-spacing-large);
      }
    }

    &__pretitle,
    &__title {
      margin-bottom: var(--emu-semantic-spacing-vertical-narrow);

      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-large);
      }
    }
  }
}

// body styles when modal is opened
.js-modal-opened {
  overflow: hidden;
  max-height: 100vh;
  padding-right: var(--scrollbar-width);
}

// modal styles when form inside it is successfully submitted
[data-component='modal'] .modal-content:has(.form--show-success-msg) {
  .modal__title {
    display: none;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'Beatrice';
  src: url('./assets/fonts/Beatrice/Beatrice-Regular.woff2') format('woff2'),
    url('./assets/fonts/Beatrice/Beatrice-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Beatrice';
  src: url('./assets/fonts/Beatrice/Beatrice-Light.woff2') format('woff2'),
    url('./assets/fonts/Beatrice/Beatrice-Light.woff') format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Beatrice';
  src: url('./assets/fonts/Beatrice/Beatrice-Light.woff2') format('woff2'),
    url('./assets/fonts/Beatrice/Beatrice-Light.woff') format('woff');
  font-weight: 200;
}

@font-face {
  font-family: 'Beatrice';
  src: url('./assets/fonts/Beatrice/Beatrice-Light.woff2') format('woff2'),
    url('./assets/fonts/Beatrice/Beatrice-Light.woff') format('woff');
  font-weight: 300;
}

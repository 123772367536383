.u-author-only {
  position: relative;
  display: none !important;

  @include aem-editor-view {
    display: block !important;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 5px dashed var(--emu-common-colors-dark-brown-200);
  }

  &::before {
    content: 'ONLY VISIBLE IN AUTHOR MODE';
    display: block;
    width: 100%;
    padding: var(--emu-common-spacing-small);
    background-color: var(--emu-common-colors-dark-brown-200);
    text-align: center;
    opacity: 0.75;
    @include letterSpacing('sm');
    @include eyebrowFont;
  }
}

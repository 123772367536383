.order-materials {
  &__label {
    &-container {
      display: flex;
      justify-content: space-between;
      gap: var(--emu-common-spacing-medium);
    }

    &-value {
      span {
        display: block;
      }
    }
  }
}

.my-account {
  @include mq('large') {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: var(--emu-semantic-spacing-horizontal-wide);
  }

  &__sidebar {
    @include mq('large') {
      width: 300px; // As per figma design
    }

    &-title {
      word-break: break-word;
    }
  }

  &__action {
    display: flex;
    align-items: center;

    &--mobile {
      justify-content: center;
    }

    &::before {
      content: '';
      width: 19px; // As per figma design
      height: 18px; // As per figma design
      margin-right: calc(0.5 * var(--emu-semantic-spacing-horizontal-narrow));
      background-image: url('../../assets/images/logout.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: inline-block;

      @include mq('large') {
        margin-right: var(--emu-semantic-spacing-horizontal-wide);
      }
    }
  }

  &__main {
    @include mq('large') {
      display: flex;
      flex: 1;
      flex-direction: column;
      max-width: 600px; // As per figma design
    }

    &-title {
      padding-bottom: calc(0.5 * var(--emu-semantic-spacing-vertical-narrow));
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: var(--emu-common-border-radius-xs);

    @include mq('large') {
      align-items: unset;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include mq('large') {
        flex-direction: row;
        justify-content: space-between;
        gap: var(--emu-semantic-spacing-horizontal-narrow);
      }
    }

    &-text {
      @include letterSpacing('sm');

      &--center-in-mobile {
        text-align: center;

        @include mq('large') {
          text-align: left;
        }
      }
    }
  }
}

.article {
  &__main {
    h1 {
      font: var(--emu-component-ingredients-title-h1-typography-narrow);

      @include mq('large') {
        font: var(--emu-component-ingredients-title-h1-typography-wide);
      }
    }

    h2 {
      font: var(--emu-component-ingredients-title-h2-typography-narrow);

      @include mq('large') {
        font: var(--emu-component-ingredients-title-h2-typography-wide);
      }
    }

    h3 {
      font: var(--emu-component-ingredients-title-h3-typography-narrow);

      @include mq('large') {
        font: var(--emu-component-ingredients-title-h3-typography-wide);
      }
    }

    h4 {
      font: var(--emu-component-ingredients-title-h4-typography-narrow);

      @include mq('large') {
        font: var(--emu-component-ingredients-title-h4-typography-wide);
      }
    }

    h5 {
      font: var(--emu-component-ingredients-title-h5-typography-narrow);

      @include mq('large') {
        font: var(--emu-component-ingredients-title-h5-typography-wide);
      }
    }
  }
}

.product-card {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;

  img {
    display: block;
  }

  > .container {
    &:has(.product-card__button),
    &:has(.product-card__button-container) {
      margin-top: auto;
    }
  }

  &__grid {
    display: grid;
    justify-content: center;
    gap: var(--emu-common-spacing-large);
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    @include mq('medium') {
      grid-template-columns: repeat(2, 350px);
    }

    @include mq('large') {
      grid-template-columns: repeat(3, 1fr);
    }

    > .container {
      width: 100%;
      max-width: 400px;
      justify-self: center;
    }
  }

  > .container:has(.product-card__button) {
    height: 100%;
    display: flex;
    width: 100%;
  }

  &__button-container,
  &__button {
    margin-top: auto;
  }
}

.text-field-with-close {
  position: relative;

  .button {
    position: static;
  }

  &:has(label) {
    .emu-button {
      transform: scale(1) translateY(-50%)
        translateY(
          calc(
            (0.5 * var(--emu-semantic-line-heights-narrow-small)) +
              (0.5 * var(--emu-common-spacing-xs))
          )
        );

      @include mq('large') {
        transform: scale(1) translateY(-50%)
          translateY(
            calc(
              (0.5 * var(--emu-semantic-line-heights-wide-medium)) +
                (0.5 * var(--emu-common-spacing-xs))
            )
          );
      }
    }
  }

  &.text-field-with-icon--on-right {
    .emu-button {
      right: 54px;
    }

    input {
      padding-right: calc(
        16px + 22px + 22px + 14px + 16px
      ); // 16 is the space of input, 22 is the size of the icon, 22px is the space before the icon, 14 is the size of close icon, 16 is the space before close icon
    }
  }

  .emu-button {
    position: absolute;
    top: 50%;
    right: 16px; // intentionally using padding value logically
    transition: all var(--emu-common-other-time-transition-short);
    opacity: 1;
    transform: scale(1) translateY(-50%);
    pointer-events: initial;
  }

  input {
    padding-right: calc(
      16px + 14px + 8px
    ); // 16 is the space before the icon, 22 is the size of the icon and 16 is space after the icon
    transition: all var(--emu-common-other-time-transition-short);
  }

  &:has(input:placeholder-shown) {
    .button {
      opacity: 0;
      pointer-events: none;
      transform: scale(0) translateY(-50%);
    }
  }

  @include aem-editor-view {
    .button,
    .emu-button {
      @include aem-editor-view {
        display: block !important;
        opacity: 1 !important;
        pointer-events: initial !important;
        transform: scale(1) translateY(0) !important;
        position: static !important;
        top: 0 !important;
      }
    }

    .button {
      position: relative !important;
    }
  }
}

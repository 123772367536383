.teaser {
  position: relative;

  // container containing the image and content as its children
  .teaser-container {
    display: flex;
    flex-direction: column-reverse;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    position: relative;

    @include mq('large') {
      display: block;
      max-width: var(--emu-semantic-sizing-container);
      position: static;
    }
  }

  .teaser-image-container {
    .cmp-teaser__image {
      img {
        width: 100%;

        @include mq('large') {
          min-height: 400px;
          object-fit: cover;
        }
      }

      &--mobile {
        display: block;

        @include mq('large') {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @include mq('large') {
          display: block;
          max-width: var(--emu-semantic-sizing-container);
        }
      }
    }
  }

  .content-container {
    width: 100%;
    position: static;
    padding: var(--emu-common-spacing-none);

    .content {
      width: 100%;
      position: static;
      padding: var(--emu-component-cards-teaser-padding-top-narrow)
        var(--emu-component-cards-teaser-padding-right-narrow)
        var(--emu-component-cards-teaser-padding-bottom-narrow)
        var(--emu-component-cards-teaser-padding-left-narrow);

      @include mq('1023px', 'max-width') {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      @include mq('large') {
        position: absolute;
        top: 74px; // height of the menu bar
        left: 50%;
        transform: translateX(-50%);
        margin-left: auto;
        margin-right: auto;
        max-width: var(--emu-semantic-sizing-container);
        width: 100%;
        height: calc(100% - 74px); // 74 is the height of the menu bar
        max-width: var(--emu-common-sizing-xxxl);
        padding: var(--emu-component-cards-teaser-padding-top-narrow)
          var(--emu-component-cards-teaser-padding-right-narrow)
          var(--emu-component-cards-teaser-padding-bottom-narrow)
          var(--emu-component-cards-teaser-padding-left-narrow);
        flex-wrap: wrap;
      }

      @include mq('desktop') {
        padding: var(--emu-common-spacing-none);
      }

      &.content-top-center {
        align-items: center;
        justify-content: flex-start;

        .aaaem-teaser__action-container {
          justify-content: center;
        }
      }

      &.content-top-right {
        align-items: flex-end;
        justify-content: flex-start;

        .aaaem-teaser__action-container {
          justify-content: flex-end;
        }
      }

      &.content-top-left {
        align-items: flex-start;
        justify-content: flex-start;

        .aaaem-teaser__action-container {
          justify-content: flex-start;
        }
      }

      &.content-middle-left {
        justify-content: center;
        align-items: flex-start;
      }

      &.content-middle-center {
        justify-content: center;
        align-items: center;

        .aaaem-teaser__action-container {
          justify-content: center;
        }
      }

      &.content-middle-right {
        justify-content: center;
        align-items: flex-end;

        .aaaem-teaser__action-container {
          justify-content: flex-end;
        }
      }

      &.content-bottom-left {
        justify-content: flex-end;
        align-items: flex-start;
      }

      &.content-bottom-center {
        justify-content: flex-end;
        align-items: center;

        .aaaem-teaser__action-container {
          justify-content: center;
        }
      }

      &.content-bottom-right {
        justify-content: flex-end;
        align-items: flex-end;

        .aaaem-teaser__action-container {
          justify-content: flex-end;
        }
      }

      // all the immediate children of the content
      > div {
        @include mq('large') {
          max-width: min(45vw, 600px); // as per the design
          margin: var(--emu-common-spacing-none);
          width: 100%;
        }
      }

      br {
        display: none;

        @include mq('large') {
          display: block;
        }
      }

      .cmp-teaser {
        &__title {
          color: inherit;
          margin: var(--emu-common-spacing-none);
        }

        &__pretitle {
          margin-top: var(--emu-common-spacing-medium);
          @include eyebrowFont;
        }

        &__subtitle {
          margin-top: var(--emu-common-spacing-medium);
          font: var(--emu-component-ingredients-title-h4-typography-narrow);

          @include mq('large') {
            font: var(--emu-component-ingredients-title-h4-typography-wide);
          }
        }

        &__description {
          margin-top: var(--emu-common-spacing-small);

          p {
            display: block !important; // important is needed override the inline style being added
          }
        }

        &__action-container {
          margin-top: var(--emu-common-spacing-large);
          display: flex;
          gap: var(--emu-common-spacing-medium);

          > a,
          > span {
            background-color: var(
              --emu-component-actions-button-color-background-primary-outline-default-light
            );
            border: var(
                --emu-component-actions-button-border-width-primary-outline
              )
              solid
              var(
                --emu-component-actions-button-color-border-primary-outline-default-light
              );
            border-radius: var(
              --emu-component-actions-button-border-radius-primary-outline
            );
            color: var(
              --emu-component-actions-button-text-color-primary-outline-default-light
            );
            outline-color: var(
              --emu-component-actions-button-color-outline-color-primary-outline-default-light
            );
            cursor: pointer;
            text-decoration: none;
            font: var(--emu-component-actions-button-text-typography-narrow);
            text-transform: uppercase;
            transition: all 0.2s linear;
            @include letterSpacing('l');
            padding: var(--emu-component-actions-button-padding-narrow);

            @include mq('medium') {
              padding: var(--emu-component-actions-button-padding-wide);
            }

            &:focus {
              background-color: var(
                --emu-component-actions-button-color-background-primary-outline-focus-light
              );
              border-color: var(
                --emu-component-actions-button-color-border-primary-outline-focus-light
              );
              color: var(
                --emu-component-actions-button-text-color-primary-outline-focus-light
              );
              outline-color: var(
                --emu-component-actions-button-color-outline-color-primary-outline-focus-light
              );
            }

            &:hover {
              background-color: var(
                --emu-component-actions-button-color-background-primary-outline-hover-light
              );
              border-color: var(
                --emu-component-actions-button-color-border-primary-outline-hover-light
              );
              color: var(
                --emu-component-actions-button-text-color-primary-outline-hover-light
              );
              outline-color: var(
                --emu-component-actions-button-color-outline-color-primary-outline-hover-light
              );
            }

            &:active {
              background-color: var(
                --emu-component-actions-button-color-background-primary-outline-active-light
              );
              border-color: var(
                --emu-component-actions-button-color-border-primary-outline-active-light
              );
              color: var(
                --emu-component-actions-button-text-color-primary-outline-active-light
              );
              outline-color: var(
                --emu-component-actions-button-color-outline-color-primary-outline-active-light
              );
              outline-style: solid;
              outline-width: var(
                --emu-component-actions-button-outline-width-primary-outline
              );
            }
          }
        }
      }
    }

    &.text-theme-light {
      .cmp-teaser {
        &__action-container {
          > a,
          > span {
            border-color: var(--emu-component-cards-teaser-text-color-light);
            color: var(--emu-component-cards-teaser-text-color-light);

            &:focus,
            &:hover,
            &:active {
              background-color: var(
                --emu-component-cards-teaser-text-color-light
              );
              border-color: var(--emu-component-cards-teaser-text-color-light);
              color: var(
                --emu-component-actions-button-text-color-primary-outline-default-light
              );
            }
          }
        }
      }
    }
  }

  .disclaimer {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);

    p {
      font: var(--emu-semantic-typography-narrow-body-xs);
      @include letterSpacing('md');
    }
  }
}

[data-component='modal'] {
  &.u-modal {
    // large modal
    &--large {
      .modal {
        &-content {
          max-width: 600px;
        }
      }
    }

    // extra large modal
    &--extra-large {
      .modal {
        &-content {
          max-width: 800px;
        }
      }
    }

    // hides the cancel cta
    &--hide-cancel-cta {
      .modal {
        &-content {
          .button-group {
            [data-ref='close-cta'] {
              display: none;
            }
          }
        }
      }
    }

    // hides the confirm cta
    &--hide-confirm-cta {
      .modal {
        &-content {
          .button-group {
            [data-ref='confirm-cta'] {
              display: none;
            }
          }
        }
      }
    }

    // reverses the ctas
    &--reverse-ctas {
      .modal {
        &-content {
          .modal-cta-container.button-group {
            flex-direction: row-reverse;
            justify-content: flex-end;
          }
        }
      }
    }

    // aligns ctas to the right
    &--ctas-on-right {
      .modal {
        &-content {
          .modal-cta-container.button-group {
            justify-content: flex-end;
          }
        }
      }

      &.u-modal--reverse-ctas {
        .modal {
          &-content {
            .modal-cta-container.button-group {
              justify-content: flex-start;
            }
          }
        }
      }
    }

    // aligns the ctas in the center
    &--ctas-in-center {
      &,
      &.u-modal--reverse-ctas {
        .modal {
          &-content {
            .modal-cta-container.button-group {
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.loader,
.emu-form__loader {
  background-color: rgba(255, 255, 255, 0.7);
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  left: var(--emu-semantic-spacing-none);
  top: var(--emu-semantic-spacing-none);
  z-index: var(--emu-common-other-z-index-layer);

  &:after {
    content: '';
    height: var(--emu-common-sizing-large);
    width: var(--emu-common-sizing-large);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-image: url('../../assets/images/spinner.gif');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &--active,
  &:has(.loader-wrapper svg) {
    display: block;
  }

  // author styles
  @include aem-editor-view {
    display: none !important; // to make sure that the styles are being overridden
  }
}

.emu-form__loader {
  svg {
    display: none;
  }
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  -webkit-overflow-scrolling: touch;
}

html,
body {
  scroll-padding-top: 70px;
  font: var(--emu-semantic-typography-narrow-body-regular);

  @include mq('large') {
    scroll-padding-top: 160px;
    font: var(--emu-semantic-typography-wide-body-regular);
  }

  // Adobe adds a negative margin, need to overwrite. Do not remove.
  .xf-content-height {
    margin: inherit;
  }

  .experiencefragment {
    padding: 0;
  }
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
span {
  margin: 0;
  padding: 0;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.cmp-image__image,
image {
  max-width: 100%;
  width: auto;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

sup {
  line-height: 0;
  position: relative;
  vertical-align: super;
  top: 3px;
  font-size: 80%;
}

a {
  color: inherit;

  &:hover,
  &:focus,
  &,
  &:active {
    text-decoration: underline;
    text-underline-offset: var(--emu-common-spacing-xs);
  }
}

ul,
ol {
  padding-left: var(--emu-common-spacing-medium);
  margin-top: var(--emu-semantic-spacing-half-line-height-narrow);
  margin-bottom: var(--emu-semantic-spacing-half-line-height-narrow);
}

label {
  cursor: pointer;
}

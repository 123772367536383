.footer {
  > .container > .aaaem-container {
    @include mq('x-large') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      padding-bottom: var(--emu-common-spacing-xl);
    }
  }

  &__main-wrapper {
    display: flex;
    flex-direction: column;
    gap: 49px; //to match figma

    @include mq('x-large') {
      gap: 33px; //to match figma
    }
  }

  &__top-section {
    display: flex;
    flex-direction: column;
    gap: 49px;

    @include mq('x-large') {
      flex-direction: row;
      justify-content: space-between;
      gap: var(--emu-semantic-spacing-none);
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: var(--emu-semantic-spacing-horizontal-narrow);

    @include mq('x-large') {
      gap: 33px;
    }
  }

  &__logo {
    a {
      display: inline-block;
    }

    img,
    svg {
      height: auto;
      width: 147px;
      display: block;
    }
  }

  &__social-links {
    display: flex;
    gap: 12px; //to match figma

    svg,
    img {
      width: 24px; //to match figma
      display: block;
    }

    a {
      display: block;
    }
  }

  &__nav {
    ul {
      margin: var(--emu-common-spacing-none);
      padding: var(--emu-common-sizing-none);
      list-style: none;

      li {
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      a {
        display: inline-block;
      }
    }
  }

  &__copyright {
    @include letterSpacing('sm');

    a[href*='tel:'] {
      &,
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}

$sidebarWidth: 232px;

.faq {
  &__container {
    &--show-results-section {
      .faq {
        &__search {
          &-results {
            display: block;

            // search results
            &-stage {
              display: block;
            }
          }

          &-main {
            .button .faq__back-to-support.emu-button {
              display: inline-flex;
            }
          }
        }

        // main tabs
        &__stage {
          display: none;
        }
      }
    }

    &--show-no-results {
      .faq {
        &__search {
          &-stage {
            display: none;
          }

          &-results-no-results-container {
            display: block;
          }
        }
      }
    }
  }

  &__search {
    &-main {
      .button .faq__back-to-support.emu-button {
        display: none;
      }

      i {
        font-style: normal;
      }
    }

    &-wrapper {
      > .container {
        max-width: $sidebarWidth;
        width: 100%;
        flex: 1;
        min-width: 100px;
      }

      .emu-form-text {
        margin: var(--emu-common-spacing-none);
      }
    }

    &-field {
      input {
        padding-top: var(--emu-semantic-spacing-half-line-height-narrow);
        padding-bottom: var(--emu-semantic-spacing-half-line-height-narrow);

        @include mq('large') {
          padding-top: var(--emu-common-spacing-small);
          padding-bottom: var(--emu-common-spacing-small);
        }
      }
    }

    &-results {
      display: none;

      @include mq('large') {
        max-width: calc(100% - $sidebarWidth);
      }

      &-no-results-container {
        text-align: center;
        display: none;

        img {
          display: inline-block;
        }
      }

      &-stage {
        display: none;

        .aaaem-accordion dt:first-child .aaaem-accordion__header {
          border-top: var(--emu-common-border-width-none);
        }
      }
    }
  }

  &__title {
    padding-bottom: var(--emu-common-spacing-medium);
    border-bottom: var(--emu-common-border-width-thin) solid
      var(--emu-common-colors-black);

    @include mq('large') {
      max-width: $sidebarWidth; // as per the design
    }
  }

  &__stage {
    display: flex;
    flex-wrap: wrap;

    @include mq('large') {
      flex-wrap: nowrap;
      gap: var(--emu-common-spacing-xl);
    }

    > .container {
      width: 100%;
    }

    > .container:has(.faq__sidebar) {
      @include mq('large') {
        max-width: $sidebarWidth;
      }
    }

    > .container:has(.faq__tabs-container) {
      @include mq('large') {
        flex: 1;
      }
    }
  }

  &__sidebar {
    .emu-form-dropdown {
      .emu-dropdown-menu__button {
        @include mq('large') {
          display: none;
        }
      }

      .emu-dropdown-menu__group {
        transition: none;
        width: 100% !important; // needed to override the inline style added via JS
      }

      &.emu-form-dropdown {
        &,
        &.js-hide-dropdown {
          @include mq('large') {
            margin: var(--emu-common-spacing-none);
          }

          .emu-dropdown-menu__group {
            @include mq('large') {
              opacity: 1;
              pointer-events: initial;
              transform: translateY(0);
              height: auto;
              position: static;
              width: 100% !important; // needed to override the inline style added via JS
              border: var(--emu-common-border-width-none);
              margin-top: var(--emu-common-spacing-none);
            }
          }

          .emu-dropdown-menu__item {
            &:first-child {
              @include mq('large') {
                border-top: var(--emu-common-border-width-none);
              }
            }

            &:last-child {
              @include mq('large') {
                border-bottom: var(--emu-common-border-width-thin) solid
                  var(--emu-common-colors-black);
              }
            }
          }
        }
      }
    }
  }

  &__tabs-container {
    .aaaem-tabs {
      &__tablist {
        display: none;
      }

      &__tabpanel .aaaem-container {
        padding: var(--emu-common-spacing-none);
      }

      &__vertical-ui {
        display: block;
      }
    }
  }

  &__loader {
    position: fixed;
    z-index: calc(var(--emu-common-other-z-index-cookie-banner) + 1);
    inset: 0;
  }
}

// author styles
.faq__search-results,
.faq__search-results-stage,
.faq__search-stage,
.faq__search-results-no-results-container {
  @include aem-editor-view {
    display: block !important;
  }
}

.faq__back-to-support {
  @include aem-editor-view {
    display: inline-flex !important;
  }
}

.faq__stage {
  @include aem-editor-view {
    display: flex !important;
  }
}

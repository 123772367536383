.teaser {
  .u-teaser--overlapped-content.aaaem-teaser {
    .content-container .content {
      @include mq('large') {
        padding-bottom: 140px;
      }
    }

    .disclaimer {
      @include mq('large') {
        bottom: 150px;
      }
    }
  }

  // when there is not content at all
  .u-teaser--no-content.aaaem-teaser {
    // class needed to add more specificity
    &.cmp-teaser {
      .content-container .content {
        padding: var(--emu-common-spacing-none);
      }
    }
  }

  // when disclaimer has to be in the flow instead of left bottom or right bottom
  .u-teaser--inline-disclaimer.aaaem-teaser {
    &.u-teaser--overlapped-content.aaaem-teaser {
      .content {
        @include mq('large') {
          padding-bottom: var(--emu-common-spacing-none);
        }
      }

      .content-container {
        @include mq('large') {
          padding-bottom: 140px;
        }
      }
    }
    .content-container {
      position: static;
      height: auto;
      padding: var(--emu-component-cards-teaser-padding-top-narrow)
        var(--emu-component-cards-teaser-padding-right-narrow)
        var(--emu-component-cards-teaser-padding-bottom-narrow)
        var(--emu-component-cards-teaser-padding-left-narrow);

      @include mq('large') {
        padding: var(--emu-common-spacing-none);
        max-width: var(--emu-common-sizing-xxxl);
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        left: 50%;
        top: 74px;
        transform: translateX(-50%);
        height: calc(100% - 74px);
      }

      &:has(.content.content-middle-left),
      &:has(.content.content-middle-center),
      &:has(.content.content-middle-right) {
        justify-content: center;
      }

      &:has(.content.content-top-left),
      &:has(.content.content-middle-left),
      &:has(.content.content-bottom-left) {
        .disclaimer {
          text-align: left;
        }
      }

      &:has(.content.content-top-center),
      &:has(.content.content-middle-center),
      &:has(.content.content-bottom-center) {
        .disclaimer {
          text-align: center;
        }
      }

      &:has(.content.content-top-right),
      &:has(.content.content-middle-right),
      &:has(.content.content-bottom-right) {
        .disclaimer {
          text-align: right;
        }
      }

      .content {
        > div {
          max-width: 80%;
        }
      }
    }

    .disclaimer {
      position: static;
      padding: var(--emu-common-spacing-none);
      margin-top: calc(0.5 * var(--emu-semantic-spacing-vertical-narrow));
    }

    .content {
      padding: var(--emu-common-spacing-none);

      @include mq('large') {
        height: auto !important; // needed to make sure it overrides any other customizations
        position: static;
        transform: none;
        max-width: none;
        display: flex;
      }

      &.content-top-center,
      &.content-middle-center,
      &.content-bottom-center {
        & + .disclaimer {
          text-align: center;
        }
      }

      &.content-top-right,
      &.content-middle-right,
      &.content-bottom-right {
        & + .disclaimer {
          text-align: right;
        }
      }

      &.content-middle-left,
      &.content-middle-center,
      &.content-middle-right {
        @include mq('large') {
          margin: var(--emu-common-spacing-none);
        }
      }
    }
  }

  // when the content should not take eyebrow header height into consideration
  .u-teaser--content-vertically-centered-to-img.aaaem-teaser {
    .content-container {
      .content {
        @include mq('large') {
          height: 100%; // needed to override other customizations
          top: 0; // needed to override other customizations
        }
      }
    }

    &.u-teaser--inline-disclaimer {
      .content-container {
        @include mq('large') {
          height: 100%;
          top: 0;
        }

        .content {
          @include mq('large') {
            height: auto;
            position: static;
          }
        }
      }
    }
  }
}

.aaaem-accordion {
  border-bottom: var(
      --emu-component-lists-accordion-item-border-width-bottom-open
    )
    solid
    var(--emu-component-lists-accordion-item-color-border-bottom-closed-light);

  &__title {
    padding-right: var(--emu-common-spacing-medium);
  }

  &__header {
    border-bottom: var(--emu-common-border-width-none);
    border-top: var(
        --emu-component-lists-accordion-item-border-width-bottom-open
      )
      solid
      var(--emu-component-lists-accordion-item-color-border-bottom-closed-light);

    &.-ba-is-active {
      .aaaem-accordion__button {
        &:not(.caret-icon) {
          .aaaem-accordion__icon {
            transform: rotate(45deg);

            &::before {
              transform: rotate(0deg);
            }
          }
        }
      }
    }
  }

  &__button {
    &.caret-icon {
      .aaaem-accordion__icon::before {
        transform: rotate(45deg) translateX(1px) translateY(-1px);
      }
    }
  }

  &__icon {
    transition-duration: var(
      --emu-component-lists-accordion-item-header-icon-transition
    );
  }

  &__item {
    &:first-child {
      .aaaem-accordion__header {
        &:first-child {
          border-top: var(--emu-common-border-width-none);
        }
      }
    }
  }
}

// adds 450px width to the parent div
div:has(>.u-width--xs) {
  width: 450px;
  max-width: 100%;
}

// adds 575px width to the parent div
div:has(>.u-width--sm) {
  width: 575px;
  max-width: 100%;
}

// adds 700px width to the parent div
div:has(>.u-width--md) {
  width: 700px;
  max-width: 100%;
}
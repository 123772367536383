.text-img-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--emu-semantic-spacing-horizontal-narrow);

  @include mq('large') {
    flex-direction: row;
    gap: var(--emu-semantic-spacing-horizontal-wide);
  }

  > .container {
    flex: 1;
    width: 100%;
  }

  > .image {
    .aaaem-image {
      img {
        display: block;
        width: 100%;
      }
    }
  }

  // Adding modifier class to make sure the content starts on the same line in desktop.
  &--align-content-top {
    @include mq('large') {
      align-items: flex-start;
    }
  }

  // Adding modifier class to make sure the content ends on the same line in desktop.
  &--align-content-bottom {
    @include mq('large') {
      align-items: flex-end;
    }
  }

  // Adding modifier class to make sure the items inside this component are swapped in desktop.
  &--reverse-in-desktop {
    flex-direction: column;

    @include mq('large') {
      flex-direction: row-reverse;
    }
  }

  // Adding modifier class to make sure the image inside this component is hidden in mobile.
  &--hide-image-in-mobile {
    > .image {
      display: none;

      @include mq('large') {
        display: block;
      }
    }
  }

  &__eyebrow,
  &__title {
    margin-bottom: var(--emu-semantic-spacing-horizontal-narrow);

    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-small);
    }
  }

  &___list,
  &__disclaimer,
  &__cta,
  &__cta-container {
    margin-top: var(--emu-semantic-spacing-horizontal-narrow);

    @include mq('large') {
      margin-top: var(--emu-common-spacing-small);
    }
  }

  &__desc {
    p {
      // for every paragraph that has a next sibling should have margin top of 22px
      & + p {
        margin-top: var(--emu-semantic-spacing-vertical-narrow);
      }
    }
  }

  &__desc,
  &__list {
    @include letterSpacing('sm');
  }

  &__list {
    ul {
      margin: var(--emu-common-spacing-none);
      padding-left: var(--emu-semantic-spacing-horizontal-narrow);
      padding-top: var(--emu-common-spacing-medium);
      padding-bottom: var(--emu-common-spacing-medium);
    }
  }

  &__cta-container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--emu-common-spacing-medium);

    .text-img-card__cta {
      margin-top: var(--emu-common-spacing-none);
    }
  }
}

.brand-showcase {
  &--as-grid {
    .brand-showcase {
      &__content-wrapper {
        @include mq('medium') {
          display: grid;
          column-gap: var(--emu-semantic-spacing-horizontal-narrow);
          row-gap: calc(2 * var(--emu-semantic-spacing-horizontal-narrow));
          grid-template-columns: repeat(2, 1fr);
        }

        &:has(> .container:only-child) {
          @include mq('large') {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
			
      &__content {
        @include mq('large') {
          padding: var(--emu-common-spacing-none);
        }
      }
    }
  }

  &__content {
    padding-top: var(--emu-semantic-spacing-horizontal-narrow);
    padding-bottom: var(--emu-semantic-spacing-horizontal-narrow);
  }

  &__eyebrow {
    margin-bottom: var(--emu-common-spacing-small);
  }

  &__title {
    .cmp-title__text {
      line-height: var(--emu-semantic-font-sizes-narrow-large);
      margin-bottom: 12px;
    }
  }

  &__desc {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

[class*='u-container--'] {
  > .container {
    margin-left: auto;
    margin-right: auto;

    > .aaaem-container {
      padding-top: var(--emu-semantic-spacing-vertical-narrow);
      padding-right: var(--emu-semantic-spacing-horizontal-narrow);
      padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
      padding-left: var(--emu-semantic-spacing-horizontal-narrow);

      @include mq('large') {
        padding-top: var(--emu-semantic-spacing-vertical-wide);
        padding-right: var(--emu-semantic-spacing-horizontal-wide);
        padding-bottom: var(--emu-semantic-spacing-vertical-wide);
        padding-left: var(--emu-semantic-spacing-horizontal-wide);
      }
    }
  }
}

// max width of 1120px
.u-container--default {
  > .container {
    max-width: var(--emu-semantic-sizing-xxxl);
  }
}

// max width of 1440px
.u-container--large {
  > .container {
    max-width: var(--emu-semantic-sizing-container);
  }
}

// min-height of 500px
.u-container--with-height-regular {
  > .container {
    > .aaaem-container {
      min-height: 500px;
    }
  }
}
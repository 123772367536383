// Auto width image
.u-image--natural {
  img.cmp-image__image {
    width: auto;
    height: auto;
    max-width: 100%;
  }
}

// Full width image
.u-image--full {
  img.cmp-image__image {
    width: 100%;
    height: auto;
  }
}

// Image with caption at the bottom left/bottom right
[class*='u-image--with-caption'] {
  position: relative;

  .cmp-image__title {
    position: absolute;
  }
}

.u-image--with-caption {
  &-white {
    .cmp-image__title {
      color: var(--emu-common-colors-white);
    }
  }

  &-bottom-left {
    .cmp-image__title {
      bottom: 4px;
      left: 4px;
    }
  }

  &-bottom-right {
    .cmp-image__title {
      bottom: 4px;
      right: 4px;
    }
  }
}

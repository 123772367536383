// adds 0.5px letter spacing
.u-letter-spacing--sm {
  @include letterSpacing('sm');
}

// adds 1px letter spacing
.u-letter-spacing--md {
  @include letterSpacing('md');
}

// adds 1.8px letter spacing
.u-letter-spacing--l {
  @include letterSpacing('l');
}

// adds 2px letter spacing
.u-letter-spacing--xl {
  @include letterSpacing('xl');
}

// adds 3px letter spacing
.u-letter-spacing--xxl {
  @include letterSpacing('xxl');
}

[class*='u-letter-spacing'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  a,
  span {
    letter-spacing: inherit !important;
  }
}

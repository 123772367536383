.resource-details {
  // download, next and previous cta buttons
  &__cta {
    a {
      &.emu-button__primary-outline {
        &:hover {
          &::after {
            filter: invert(1);
          }
        }
      }

      &::after {
        content: '';
        width: 19px; // to match the design.
        height: 19px; // to match the design.
        display: inline-block;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &--next,
    &--prev {
      a {
        &::after {
          background-image: url('../../assets/images/prev-icon.png');
        }
      }

      span {
        text-transform: none;
      }
    }

    &--next {
      display: flex;
      justify-content: flex-end;

      @include mq('large') {
        margin-bottom: -2px;
      }

      a {
        &::after {
          transform: rotate(180deg);
        }
      }
    }

    &--prev {
      a {
        flex-direction: row-reverse;
        &::after {
          background-image: url('../../assets/images/prev-icon.png');
        }
      }
    }

    &--download,
    &--order {
      a {
        min-width: 160px;
        justify-content: center;
      }
    }

    &--download {
      a {
        &::after {
          background-image: url('../../assets/images/download.png');
        }
      }
    }

    &--order {
      a {
        &::after {
          background-image: url('../../assets/images/order.png');
        }
      }
    }
  }

  &__title {
    @include mq('large') {
      font-weight: var(--emu-common-font-weight-light);
    }
  }

  &__media {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &--show-video {
      .resource-details__image {
        display: none;
      }
    }
  }

  &__videos {
    width: 100%;
    display: block;
  }

  &__video {
    margin-bottom: var(--emu-common-spacing-large);

    &:last-child {
      margin-bottom: var(
        --emu-common-spacing-none
      ) !important; //important needed to override utility styles
    }

    &-title {
      @include letterSpacing('sm');
    }

    &-container {
      max-width: 550px; // As per the figma design
      margin-left: calc(-1 * var(--emu-semantic-spacing-horizontal-narrow));
      margin-right: calc(-1 * var(--emu-semantic-spacing-horizontal-narrow));

      @include mq('large') {
        margin-left: var(--emu-common-spacing-none);
        margin-right: var(--emu-common-spacing-none);
      }
    }
  }

  &__image {
    max-width: 318px; // As per the figma design.
    width: 100%;
  }

  &__content {
    max-width: 584px; // As per figma design
  }

  &__list {
    list-style: none;
    padding-left: var(--emu-common-spacing-none);

    li {
      display: flex;
      gap: var(--emu-semantic-sizing-two-line-height-narrow);
      align-items: center;

      &:last-child {
        margin-bottom: var(
          --emu-common-spacing-none
        ) !important; // needed to override the styles from the util classes
      }

      label {
        min-width: 90px; // rounded off from the value in Figma
        max-width: 40%;
      }
    }
  }
}

.text-field-with-icon {
  position: relative;

  &:has(label) {
    img {
      transform: translateY(-50%)
        translateY(
          calc(
            (0.5 * var(--emu-semantic-line-heights-narrow-small)) +
              (0.5 * var(--emu-common-spacing-xs))
          )
        );

      @include mq('large') {
        transform: translateY(-50%)
          translateY(calc(0.5 * var(--emu-semantic-line-heights-wide-medium)))
          translateY(calc(0.5 * var(--emu-common-spacing-xs)));
      }
    }
  }

  &.text-field-with-icon--on-right {
    img {
      right: 16px;
      left: auto;
    }

    input {
      padding-right: calc(
        16px + 22px + 22px
      ); // 16 is the space before the icon, 22 is the size of the icon and 22 is space after the icon
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    width: 22px; // by design
    height: 22px; // by design
    height: auto;
    object-fit: contain;
    object-position: left center;
    transition: all var(--emu-common-other-time-transition-short);

    @include aem-editor-view {
      position: static !important;
      transform: none !important;
    }
  }

  &:not(.text-field-with-icon--on-right) {
    input {
      padding-left: calc(
        16px + 22px + 22px
      ); // 16 is the space before the icon, 22 is the size of the icon and 22 is space after the icon
      transition: all var(--emu-common-other-time-transition-short);
    }
  }
}

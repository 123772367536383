
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-light: #F5ECEA !default;
$emu-semantic-colors-surface-dark: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-modal-backdrop: rgba(0, 0, 0, 0.4) !default;
$emu-semantic-colors-actions-primary-light: #CA9A8E !default;
$emu-semantic-colors-actions-primary-dark: #F2D9D0 !default;
$emu-semantic-colors-actions-primary-default-light: #CA9A8E !default;
$emu-semantic-colors-actions-primary-default-dark: #F2D9D0 !default;
$emu-semantic-colors-actions-primary-hover-light: #B4705F !default;
$emu-semantic-colors-actions-primary-hover-dark: #F7E8E3 !default;
$emu-semantic-colors-actions-primary-active-light: #CA9A8E !default;
$emu-semantic-colors-actions-primary-active-dark: #F2D9D0 !default;
$emu-semantic-colors-actions-primary-focus-light: #CA9A8E !default;
$emu-semantic-colors-actions-primary-focus-dark: #F2D9D0 !default;
$emu-semantic-colors-actions-primary-disabled-light: #E6E5E5 !default;
$emu-semantic-colors-actions-primary-disabled-dark: #D8D5D5 !default;
$emu-semantic-colors-actions-primary-error-light: #FF1D15 !default;
$emu-semantic-colors-actions-primary-error-dark: #FF1D15 !default;
$emu-semantic-colors-actions-on-primary-light: #000000 !default;
$emu-semantic-colors-actions-on-primary-dark: #000000 !default;
$emu-semantic-colors-actions-secondary-light: #492728 !default;
$emu-semantic-colors-actions-secondary-dark: #DDC1CC !default;
$emu-semantic-colors-actions-secondary-default-light: #492728 !default;
$emu-semantic-colors-actions-secondary-default-dark: #DDC1CC !default;
$emu-semantic-colors-actions-secondary-hover-light: #3C2021 !default;
$emu-semantic-colors-actions-secondary-hover-dark: #C08CA1 !default;
$emu-semantic-colors-actions-secondary-active-light: #3C2021 !default;
$emu-semantic-colors-actions-secondary-active-dark: #C08CA1 !default;
$emu-semantic-colors-actions-secondary-focus-light: #3C2021 !default;
$emu-semantic-colors-actions-secondary-focus-dark: #C08CA1 !default;
$emu-semantic-colors-actions-secondary-disabled-light: #E6E5E5 !default;
$emu-semantic-colors-actions-secondary-disabled-dark: #E6E5E5 !default;
$emu-semantic-colors-actions-on-secondary-light: #FFFFFF !default;
$emu-semantic-colors-actions-on-secondary-dark: #000000 !default;
$emu-semantic-colors-text-light: #000000 !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-text-accent: #492728 !default;
$emu-semantic-colors-foreground-light: #FFFFFF !default;
$emu-semantic-colors-foreground-dark: #000000 !default;
$emu-semantic-colors-primary-light: #CA9A8E !default;
$emu-semantic-colors-primary-dark: #FFFFFF !default;
$emu-semantic-colors-secondary-light: #492728 !default;
$emu-semantic-colors-secondary-dark: #FFFFFF !default;
$emu-semantic-colors-tertiary-light: #EBDDDB !default;
$emu-semantic-colors-tertiary-dark: #FFFFFF !default;
$emu-semantic-colors-error-light: #FF1D15 !default;
$emu-semantic-colors-error-dark: #FFFFFF !default;
$emu-semantic-colors-background-light: #FFFFFF !default;
$emu-semantic-colors-background-dark: #FFFFFF !default;
$emu-semantic-font-weight-bold: 700 !default;
$emu-semantic-font-weight-light: 300 !default;
$emu-semantic-font-weight-black: 900 !default;
$emu-semantic-font-weight-regular: 400 !default;
$emu-semantic-font-families-body: 'Roboto', sans-serif !default;
$emu-semantic-font-families-heading: Beatrice, sans-serif !default;
$emu-semantic-font-families-mono: Courier, monospace !default;
$emu-semantic-spacing-none: 0px !default;
$emu-semantic-spacing-one-line-height-wide: 22px !default;
$emu-semantic-spacing-one-line-height-narrow: 22px !default;
$emu-semantic-spacing-two-line-height-wide: calc(22px*2) !default;
$emu-semantic-spacing-two-line-height-narrow: calc(22px*2) !default;
$emu-semantic-spacing-three-line-height-wide: calc(22px*3) !default;
$emu-semantic-spacing-three-line-height-narrow: calc(22px*3) !default;
$emu-semantic-spacing-rem-horizontal: 0px !default;
$emu-semantic-spacing-horizontal-narrow: 22px !default;
$emu-semantic-spacing-horizontal-wide: 44px !default;
$emu-semantic-spacing-horizontal-onerem-narrow: 1rem !default;
$emu-semantic-spacing-horizontal-onerem-wide: 1rem !default;
$emu-semantic-spacing-horizontal-tworem-narrow: calc(1rem*2) !default;
$emu-semantic-spacing-horizontal-tworem-wide: calc(1rem*2) !default;
$emu-semantic-spacing-rem-vertical: 0px !default;
$emu-semantic-spacing-vertical-narrow: 22px !default;
$emu-semantic-spacing-vertical-wide: 22px !default;
$emu-semantic-spacing-half-line-height-wide: calc(22px*.5) !default;
$emu-semantic-spacing-half-line-height-narrow: calc(22px*.5) !default;
$emu-semantic-spacing-quarter-line-height-wide: calc(22px*.25) !default;
$emu-semantic-spacing-quarter-line-height-narrow: calc(22px*.25) !default;
$emu-semantic-font-sizes-narrow-medium: 20px !default;
$emu-semantic-font-sizes-narrow-large: 24px !default;
$emu-semantic-font-sizes-narrow-xl: 32px !default;
$emu-semantic-font-sizes-narrow-xxl: 36px !default;
$emu-semantic-font-sizes-narrow-xxxl: 40px !default;
$emu-semantic-font-sizes-narrow-small: 16px !default;
$emu-semantic-font-sizes-narrow-xs: 12px !default;
$emu-semantic-font-sizes-wide-medium: 20px !default;
$emu-semantic-font-sizes-wide-large: 26px !default;
$emu-semantic-font-sizes-wide-xl: 32px !default;
$emu-semantic-font-sizes-wide-xxl: 40px !default;
$emu-semantic-font-sizes-wide-xxxl: 48px !default;
$emu-semantic-font-sizes-wide-small: 16px !default;
$emu-semantic-font-sizes-wide-xs: 12px !default;
$emu-semantic-line-heights-narrow-medium: 22px !default;
$emu-semantic-line-heights-narrow-large: 28px !default;
$emu-semantic-line-heights-narrow-xl: 34px !default;
$emu-semantic-line-heights-narrow-xxl: 42px !default;
$emu-semantic-line-heights-narrow-xxxl: 48px !default;
$emu-semantic-line-heights-narrow-small: 16px !default;
$emu-semantic-line-heights-wide-large: 32px !default;
$emu-semantic-line-heights-wide-medium: 24px !default;
$emu-semantic-line-heights-wide-xl: 42px !default;
$emu-semantic-line-heights-wide-xxl: 48px !default;
$emu-semantic-line-heights-wide-xxxl: 56px !default;
$emu-semantic-line-heights-wide-small: 18px !default;
$emu-semantic-border-radius-xs: 2px !default;
$emu-semantic-border-radius-small: 8px !default;
$emu-semantic-border-radius-medium: 16px !default;
$emu-semantic-border-radius-large: 32px !default;
$emu-semantic-border-radius-none: 0px !default;
$emu-semantic-border-width-thin: 1px !default;
$emu-semantic-border-width-medium: 2px !default;
$emu-semantic-border-width-thick: 4px !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-outline-width-thick: thick !default;
$emu-semantic-outline-width-unset: unset !default;
$emu-semantic-sizing-none: 0px !default;
$emu-semantic-sizing-xxs: 1px !default;
$emu-semantic-sizing-xs: 8px !default;
$emu-semantic-sizing-small: 16px !default;
$emu-semantic-sizing-medium: 32px !default;
$emu-semantic-sizing-large: 64px !default;
$emu-semantic-sizing-xl: 128px !default;
$emu-semantic-sizing-xxl: 256px !default;
$emu-semantic-sizing-xxxl: 1120px !default;
$emu-semantic-sizing-container: 1440px !default;
$emu-semantic-sizing-breakpoints-small: 320px !default;
$emu-semantic-sizing-breakpoints-medium: 768px !default;
$emu-semantic-sizing-breakpoints-large: 1024px !default;
$emu-semantic-sizing-breakpoints-x-large: 1120px !default;
$emu-semantic-sizing-breakpoints-desktop: 1200px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1440px !default;
$emu-semantic-sizing-one-line-height-narrow: 20px !default;
$emu-semantic-sizing-one-line-height-wide: 20px !default;
$emu-semantic-sizing-two-line-height-narrow: calc(20px*2) !default;
$emu-semantic-sizing-two-line-height-wide: calc(20px*2) !default;
$emu-semantic-sizing-three-line-height-narrow: calc(20px*3) !default;
$emu-semantic-sizing-three-line-height-wide: calc(20px*3) !default;
$emu-semantic-sizing-max-width-text-narrow: 40em !default;
$emu-semantic-sizing-max-width-text-wide: 55em !default;
$emu-semantic-typography-narrow-headings-xxxl: 300 40px/48px Beatrice, sans-serif !default;
$emu-semantic-typography-narrow-headings-xxl: 300 36px/48px Beatrice, sans-serif !default;
$emu-semantic-typography-narrow-headings-xl: 400 32px/42px Beatrice, sans-serif !default;
$emu-semantic-typography-narrow-headings-large: 300 24px/28px Beatrice, sans-serif !default;
$emu-semantic-typography-narrow-headings-medium: 300 20px/28px Beatrice, sans-serif !default;
$emu-semantic-typography-narrow-body-regular: 400 16px/22px 'Roboto', sans-serif !default;
$emu-semantic-typography-narrow-body-xs: 400 12px/18px 'Roboto', sans-serif !default;
$emu-semantic-typography-narrow-body-small: 300 16px/16px 'Roboto', sans-serif !default;
$emu-semantic-typography-narrow-body-large: 400 20px/28px 'Roboto', sans-serif !default;
$emu-semantic-typography-narrow-body-xl: 400 32px/28px 'Roboto', sans-serif !default;
$emu-semantic-typography-narrow-body-eyebrow: 400 12px/16px 'Roboto', sans-serif !default;
$emu-semantic-typography-narrow-body-link: 400 12px/22px Beatrice, sans-serif !default;
$emu-semantic-typography-narrow-body-cta: 400 12px/16px 'Roboto', sans-serif !default;
$emu-semantic-typography-wide-headings-xxxl: 300 48px/56px Beatrice, sans-serif !default;
$emu-semantic-typography-wide-headings-xxl: 300 40px/48px Beatrice, sans-serif !default;
$emu-semantic-typography-wide-headings-xl: 400 32px/42px Beatrice, sans-serif !default;
$emu-semantic-typography-wide-headings-large: 300 26px/32px Beatrice, sans-serif !default;
$emu-semantic-typography-wide-headings-medium: 300 20px/32px Beatrice, sans-serif !default;
$emu-semantic-typography-wide-body-regular: 400 16px/24px 'Roboto', sans-serif !default;
$emu-semantic-typography-wide-body-xs: 400 12px/18px 'Roboto', sans-serif !default;
$emu-semantic-typography-wide-body-small: 400 12px/18px 'Roboto', sans-serif !default;
$emu-semantic-typography-wide-body-large: 400 20px/24px 'Roboto', sans-serif !default;
$emu-semantic-typography-wide-body-xl: 400 26px/32px 'Roboto', sans-serif !default;
$emu-semantic-typography-wide-body-eyebrow: 400 12px/18px 'Roboto', sans-serif !default;
$emu-semantic-typography-wide-body-link: 400 12px/18px Beatrice, sans-serif !default;
$emu-semantic-typography-wide-body-cta: 400 12px/24px 'Roboto', sans-serif !default;
$emu-semantic-border-black: [object Object] !default;
$emu-semantic-border-white: [object Object] !default;

$semantic: (
  'semantic': (
    'colors': (
      'none': $emu-semantic-colors-none,
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'light': $emu-semantic-colors-actions-primary-light,
          'dark': $emu-semantic-colors-actions-primary-dark,
          'default': (
            'light': $emu-semantic-colors-actions-primary-default-light,
            'dark': $emu-semantic-colors-actions-primary-default-dark
          ),
          'hover': (
            'light': $emu-semantic-colors-actions-primary-hover-light,
            'dark': $emu-semantic-colors-actions-primary-hover-dark
          ),
          'active': (
            'light': $emu-semantic-colors-actions-primary-active-light,
            'dark': $emu-semantic-colors-actions-primary-active-dark
          ),
          'focus': (
            'light': $emu-semantic-colors-actions-primary-focus-light,
            'dark': $emu-semantic-colors-actions-primary-focus-dark
          ),
          'disabled': (
            'light': $emu-semantic-colors-actions-primary-disabled-light,
            'dark': $emu-semantic-colors-actions-primary-disabled-dark
          ),
          'error': (
            'light': $emu-semantic-colors-actions-primary-error-light,
            'dark': $emu-semantic-colors-actions-primary-error-dark
          )
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        ),
        'secondary': (
          'light': $emu-semantic-colors-actions-secondary-light,
          'dark': $emu-semantic-colors-actions-secondary-dark,
          'default': (
            'light': $emu-semantic-colors-actions-secondary-default-light,
            'dark': $emu-semantic-colors-actions-secondary-default-dark
          ),
          'hover': (
            'light': $emu-semantic-colors-actions-secondary-hover-light,
            'dark': $emu-semantic-colors-actions-secondary-hover-dark
          ),
          'active': (
            'light': $emu-semantic-colors-actions-secondary-active-light,
            'dark': $emu-semantic-colors-actions-secondary-active-dark
          ),
          'focus': (
            'light': $emu-semantic-colors-actions-secondary-focus-light,
            'dark': $emu-semantic-colors-actions-secondary-focus-dark
          ),
          'disabled': (
            'light': $emu-semantic-colors-actions-secondary-disabled-light,
            'dark': $emu-semantic-colors-actions-secondary-disabled-dark
          )
        ),
        'on-secondary': (
          'light': $emu-semantic-colors-actions-on-secondary-light,
          'dark': $emu-semantic-colors-actions-on-secondary-dark
        )
      ),
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark,
        'accent': $emu-semantic-colors-text-accent
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      ),
      'primary': (
        'light': $emu-semantic-colors-primary-light,
        'dark': $emu-semantic-colors-primary-dark
      ),
      'secondary': (
        'light': $emu-semantic-colors-secondary-light,
        'dark': $emu-semantic-colors-secondary-dark
      ),
      'tertiary': (
        'light': $emu-semantic-colors-tertiary-light,
        'dark': $emu-semantic-colors-tertiary-dark
      ),
      'error': (
        'light': $emu-semantic-colors-error-light,
        'dark': $emu-semantic-colors-error-dark
      ),
      'background': (
        'light': $emu-semantic-colors-background-light,
        'dark': $emu-semantic-colors-background-dark
      )
    ),
    'fontWeight': (
      'bold': $emu-semantic-font-weight-bold,
      'light': $emu-semantic-font-weight-light,
      'black': $emu-semantic-font-weight-black,
      'regular': $emu-semantic-font-weight-regular
    ),
    'fontFamilies': (
      'body': $emu-semantic-font-families-body,
      'heading': $emu-semantic-font-families-heading,
      'mono': $emu-semantic-font-families-mono
    ),
    'spacing': (
      'none': $emu-semantic-spacing-none,
      'oneLineHeight': (
        'wide': $emu-semantic-spacing-one-line-height-wide,
        'narrow': $emu-semantic-spacing-one-line-height-narrow
      ),
      'twoLineHeight': (
        'wide': $emu-semantic-spacing-two-line-height-wide,
        'narrow': $emu-semantic-spacing-two-line-height-narrow
      ),
      'threeLineHeight': (
        'wide': $emu-semantic-spacing-three-line-height-wide,
        'narrow': $emu-semantic-spacing-three-line-height-narrow
      ),
      '_rem_horizontal': $emu-semantic-spacing-rem-horizontal,
      'horizontal': (
        'narrow': $emu-semantic-spacing-horizontal-narrow,
        'wide': $emu-semantic-spacing-horizontal-wide,
        'onerem': (
          'narrow': $emu-semantic-spacing-horizontal-onerem-narrow,
          'wide': $emu-semantic-spacing-horizontal-onerem-wide
        ),
        'tworem': (
          'narrow': $emu-semantic-spacing-horizontal-tworem-narrow,
          'wide': $emu-semantic-spacing-horizontal-tworem-wide
        )
      ),
      '_rem_vertical': $emu-semantic-spacing-rem-vertical,
      'vertical': (
        'narrow': $emu-semantic-spacing-vertical-narrow,
        'wide': $emu-semantic-spacing-vertical-wide
      ),
      'halfLineHeight': (
        'wide': $emu-semantic-spacing-half-line-height-wide,
        'narrow': $emu-semantic-spacing-half-line-height-narrow
      ),
      'quarterLineHeight': (
        'wide': $emu-semantic-spacing-quarter-line-height-wide,
        'narrow': $emu-semantic-spacing-quarter-line-height-narrow
      )
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl,
        'small': $emu-semantic-font-sizes-narrow-small,
        'xs': $emu-semantic-font-sizes-narrow-xs
      ),
      'wide': (
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl,
        'small': $emu-semantic-font-sizes-wide-small,
        'xs': $emu-semantic-font-sizes-wide-xs
      )
    ),
    'lineHeights': (
      'narrow': (
        'medium': $emu-semantic-line-heights-narrow-medium,
        'large': $emu-semantic-line-heights-narrow-large,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl,
        'small': $emu-semantic-line-heights-narrow-small
      ),
      'wide': (
        'large': $emu-semantic-line-heights-wide-large,
        'medium': $emu-semantic-line-heights-wide-medium,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl,
        'small': $emu-semantic-line-heights-wide-small
      )
    ),
    'borderRadius': (
      'xs': $emu-semantic-border-radius-xs,
      'small': $emu-semantic-border-radius-small,
      'medium': $emu-semantic-border-radius-medium,
      'large': $emu-semantic-border-radius-large,
      'none': $emu-semantic-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-semantic-border-width-thin,
      'medium': $emu-semantic-border-width-medium,
      'thick': $emu-semantic-border-width-thick,
      'none': $emu-semantic-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium,
      'thick': $emu-semantic-outline-width-thick,
      'unset': $emu-semantic-outline-width-unset
    ),
    'sizing': (
      'none': $emu-semantic-sizing-none,
      'xxs': $emu-semantic-sizing-xxs,
      'xs': $emu-semantic-sizing-xs,
      'small': $emu-semantic-sizing-small,
      'medium': $emu-semantic-sizing-medium,
      'large': $emu-semantic-sizing-large,
      'xl': $emu-semantic-sizing-xl,
      'xxl': $emu-semantic-sizing-xxl,
      'xxxl': $emu-semantic-sizing-xxxl,
      'container': $emu-semantic-sizing-container,
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'large': $emu-semantic-sizing-breakpoints-large,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'desktop': $emu-semantic-sizing-breakpoints-desktop,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large
      ),
      'oneLineHeight': (
        'narrow': $emu-semantic-sizing-one-line-height-narrow,
        'wide': $emu-semantic-sizing-one-line-height-wide
      ),
      'twoLineHeight': (
        'narrow': $emu-semantic-sizing-two-line-height-narrow,
        'wide': $emu-semantic-sizing-two-line-height-wide
      ),
      'threeLineHeight': (
        'narrow': $emu-semantic-sizing-three-line-height-narrow,
        'wide': $emu-semantic-sizing-three-line-height-wide
      ),
      'maxWidthText': (
        'narrow': $emu-semantic-sizing-max-width-text-narrow,
        'wide': $emu-semantic-sizing-max-width-text-wide
      )
    ),
    'typography': (
      'narrow': (
        'headings': (
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-narrow-body-regular,
          'xs': $emu-semantic-typography-narrow-body-xs,
          'small': $emu-semantic-typography-narrow-body-small,
          'large': $emu-semantic-typography-narrow-body-large,
          'xl': $emu-semantic-typography-narrow-body-xl,
          'eyebrow': $emu-semantic-typography-narrow-body-eyebrow,
          'link': $emu-semantic-typography-narrow-body-link,
          'cta': $emu-semantic-typography-narrow-body-cta
        )
      ),
      'wide': (
        'headings': (
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-wide-body-regular,
          'xs': $emu-semantic-typography-wide-body-xs,
          'small': $emu-semantic-typography-wide-body-small,
          'large': $emu-semantic-typography-wide-body-large,
          'xl': $emu-semantic-typography-wide-body-xl,
          'eyebrow': $emu-semantic-typography-wide-body-eyebrow,
          'link': $emu-semantic-typography-wide-body-link,
          'cta': $emu-semantic-typography-wide-body-cta
        )
      )
    ),
    'border': (
      'black': $emu-semantic-border-black,
      'white': $emu-semantic-border-white
    )
  )
);

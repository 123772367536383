.tip-banner {
  display: flex;
  align-items: center;
  gap: var(--emu-semantic-spacing-horizontal-narrow);
  position: relative;
  padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  border-left-width: var(--emu-common-border-width-thick);
  border-left-style: solid;

  &--error {
    border-left-color: var(--emu-common-colors-red-500);

    .tip-banner__icon path {
      stroke: var(--emu-common-colors-red-500);
    }
  }

  &--success {
    border-left-color: #04aa6d; // no variable available

    .tip-banner__icon path {
      stroke: #04aa6d; // no variable available
    }
  }

  &--info {
    border-left-color: var(--emu-common-colors-dark-brown-500);

    .tip-banner__icon path {
      stroke: var(--emu-common-colors-dark-brown-500);
    }
  }

  &--warning {
    border-left-color: var(--emu-common-colors-red-300);

    .tip-banner__icon path {
      stroke: var(--emu-common-colors-red-300);
    }
  }

  &--hidden {
    display: none;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__img {
    max-width: var(--emu-common-sizing-small);
  }

  &__icon {
    min-height: var(--emu-common-sizing-small);
    min-width: var(--emu-common-sizing-small);
  }
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

// use function instead of mixin to calculate z-index, ie: z-index: z-index(ccpa + 20)
@function z-index($key) {
  @return map-deep-get($common, common, other, zIndex, $key);
}

@function breakpoints() {
  @return map-deep-get($semantic, semantic, sizing, breakpoints);
}

@mixin z-index($key) {
  z-index: z-index($key);
}

$letter-spacings: (
  'default': normal,
  'sm': 0.5px,
  'md': 1px,
  'l': 1.8px,
  'xl': 2px,
  'xxl': 3px,
);

@mixin letterSpacing($type: 'l') {
  letter-spacing: map-get($letter-spacings, $type) !important;
}

@mixin eyebrowFont {
  font: var(--emu-semantic-typography-narrow-body-eyebrow);
  @include letterSpacing('xxl');
  text-transform: uppercase;

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-body-eyebrow);
  }
}

// generating spaces, padding and bottom
$spacing-ratios: (
  'sm': 0.5,
  'md': 1,
  'l': 2,
);
$spacings: (
  'pt': 'padding-top',
  'pb': 'padding-bottom',
  'pr': 'padding-right',
  'pl': 'padding-left',
  'mt': 'margin-top',
  'mb': 'margin-bottom',
  'mr': 'margin-right',
  'ml': 'margin-left',
  'p': 'padding',
  'm': 'margin',
);
$devices: 'narrow', 'wide', 'all';
$spacing: var(--emu-semantic-spacing-horizontal-narrow);

@mixin generateSpaceUtilities() {
  @each $device in $devices {
    @each $direction, $value in $spacings {
      @each $ratio, $ratioVal in $spacing-ratios {
        .u-spacing-#{$device}-#{$direction}-#{$ratio} {
          @if str-index($string: #{$device}, $substring: 'narrow') {
            @media (max-width: 1023px) {
              #{$value}: calc($ratioVal * $spacing) !important;
            }
          } @else if str-index($string: #{$device}, $substring: 'wide') {
            @media (min-width: 1024px) {
              #{$value}: calc($ratioVal * $spacing) !important;
            }
          } @else {
            #{$value}: calc($ratioVal * $spacing) !important;
          }
        }
      }
    }
  }
}

// generates and adds space related utilities. Intentionally commented to take confirmation
// @include generateSpaceUtilities();

// special spacing utility classes

// important declarations are needed in this file to make sure that this overrides any other styles being added

// 22px on all sides.
.u-p-regular {
  padding-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  padding-right: var(--emu-semantic-spacing-horizontal-narrow) !important;
  padding-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
  padding-left: var(--emu-semantic-spacing-horizontal-narrow) !important;

  @include mq('large') {
    padding-top: var(--emu-semantic-spacing-vertical-wide) !important;
    padding-right: var(--emu-semantic-spacing-horizontal-wide) !important;
    padding-bottom: var(--emu-semantic-spacing-vertical-wide) !important;
    padding-left: var(--emu-semantic-spacing-horizontal-wide) !important;
  }
}

// 11px margin top
.u-mt-half {
  margin-top: calc(
    0.5 * var(--emu-semantic-spacing-vertical-narrow)
  ) !important;
}

// 11px margin top in mobile only
.u-mt-half-narrow {
  @include mq('1023px', 'max-width') {
    margin-top: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 11px margin top in desktop only
.u-mt-half-wide {
  @include mq(large) {
    margin-top: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 11px padding top
.u-pt-half {
  padding-top: calc(
    0.5 * var(--emu-semantic-spacing-vertical-narrow)
  ) !important;
}

// 11px padding top in mobile only
.u-pt-half-narrow {
  @include mq('1023px', 'max-width') {
    padding-top: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 11px padding top in desktop only
.u-pt-half-wide {
  @include mq('large') {
    padding-top: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 11px margin bottom
.u-mb-half {
  margin-bottom: calc(
    0.5 * var(--emu-semantic-spacing-vertical-narrow)
  ) !important;
}

// 11px margin bottom in mobile only
.u-mb-half-narrow {
  @include mq('1023px', 'max-width') {
    margin-bottom: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 11px margin bottom in desktop only
.u-mb-half-wide {
  @include mq('large') {
    margin-bottom: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 11px padding bottom
.u-pb-half {
  padding-bottom: calc(
    0.5 * var(--emu-semantic-spacing-vertical-narrow)
  ) !important;
}

// 11px padding bottom in mobile only
.u-pb-half-narrow {
  @include mq('1023px', 'max-width') {
    padding-bottom: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 11px padding bottom in desktop only
.u-pb-half-wide {
  @include mq('large') {
    padding-bottom: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 11px margin top and margin bottom
.u-my-half {
  margin-bottom: calc(
    0.5 * var(--emu-semantic-spacing-vertical-narrow)
  ) !important;
  margin-top: calc(
    0.5 * var(--emu-semantic-spacing-vertical-narrow)
  ) !important;
}

// 11px margin top and margin bottom in mobile only
.u-my-half-narrow {
  @include mq('1023px', 'max-width') {
    margin-bottom: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
    margin-top: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 11px margin top and margin bottom in desktop only
.u-my-half-wide {
  @include mq('large') {
    margin-bottom: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
    margin-top: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 11px padding top and padding bottom
.u-py-half {
  padding-bottom: calc(
    0.5 * var(--emu-semantic-spacing-vertical-narrow)
  ) !important;
  padding-top: calc(
    0.5 * var(--emu-semantic-spacing-vertical-narrow)
  ) !important;
}

// 11px padding top and padding bottom in mobile only
.u-py-half-narrow {
  @include mq('1023px', 'max-width') {
    padding-bottom: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
    padding-top: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 11px padding top and padding bottom in desktop only
.u-py-half-wide {
  @include mq('large') {
    padding-bottom: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
    padding-top: calc(
      0.5 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 22px margin top
.u-mt-regular {
  margin-top: var(--emu-semantic-spacing-vertical-narrow) !important;
}

// 22px margin top in mobile only
.u-mt-regular-narrow {
  @include mq('1023px', 'max-width') {
    margin-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

// 22px margin top in desktop only
.u-mt-regular-wide {
  @include mq('large') {
    margin-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

// 22px padding top
.u-pt-regular {
  padding-top: var(--emu-semantic-spacing-vertical-narrow) !important;
}

// 22px padding top in mobile only
.u-pt-regular-narrow {
  @include mq('1023px', 'max-width') {
    padding-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

// 22px padding top in desktop only
.u-pt-regular-wide {
  @include mq('large') {
    padding-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

// 22px margin bottom
.u-mb-regular {
  margin-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
}

// 22px margin bottom in mobile only
.u-mb-regular-narrow {
  @include mq('1023px', 'max-width') {
    margin-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

// 22px margin bottom in desktop only
.u-mb-regular-wide {
  @include mq('large') {
    margin-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

// 22px padding bottom
.u-pb-regular {
  padding-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
}

// 22px padding bottom in mobile only
.u-pb-regular-narrow {
  @include mq('1023px', 'max-width') {
    padding-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

// 22px padding bottom in desktop only
.u-pb-regular-wide {
  @include mq('large') {
    padding-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

// 22px margin top and margin bottom
.u-my-regular {
  margin-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
  margin-top: var(--emu-semantic-spacing-vertical-narrow) !important;
}

// 22px margin top and margin bottom in mobile only
.u-my-regular-narrow {
  @include mq('1023px', 'max-width') {
    margin-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
    margin-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

// 22px margin top and margin bottom in desktop only
.u-my-regular-wide {
  @include mq('large') {
    margin-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
    margin-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

// 22px padding top and padding bottom
.u-py-regular {
  padding-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
  padding-top: var(--emu-semantic-spacing-vertical-narrow) !important;
}

// 22px padding top and padding bottom in mobile only
.u-py-regular-narrow {
  @include mq('1023px', 'max-width') {
    padding-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
    padding-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

// 22px padding top and padding bottom in desktop only
.u-py-regular-wide {
  @include mq('large') {
    padding-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
    padding-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

// 44px margin top
.u-mt-double {
  margin-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
}

// 44px margin top in mobile only
.u-mt-double-narrow {
  @include mq('1023px', 'max-width') {
    margin-top: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 44px margin top in desktop only
.u-mt-double-wide {
  @include mq('large') {
    margin-top: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 44px padding top
.u-pt-double {
  padding-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
}

// 44px padding top in mobile only
.u-pt-double-narrow {
  @include mq('1023px', 'max-width') {
    padding-top: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 44px padding top in desktop only
.u-pt-double-wide {
  @include mq('large') {
    padding-top: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 44px margin bottom
.u-mb-double {
  margin-bottom: calc(
    2 * var(--emu-semantic-spacing-vertical-narrow)
  ) !important;
}

// 44px margin bottom in mobile only
.u-mb-double-narrow {
  @include mq('1023px', 'max-width') {
    margin-bottom: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 44px margin bottom in desktop only
.u-mb-double-wide {
  @include mq('large') {
    margin-bottom: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 44px padding bottom
.u-pb-double {
  padding-bottom: calc(
    2 * var(--emu-semantic-spacing-vertical-narrow)
  ) !important;
}

// 44px padding bottom in mobile only
.u-pb-double-narrow {
  @include mq('1023px', 'max-width') {
    padding-bottom: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 44px padding bottom in desktop only
.u-pb-double-wide {
  @include mq('large') {
    padding-bottom: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 44px margin top and margin bottom
.u-my-double {
  margin-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  margin-bottom: calc(
    2 * var(--emu-semantic-spacing-vertical-narrow)
  ) !important;
}

// 44px margin top and margin bottom in mobile only
.u-my-double-narrow {
  @include mq('1023px', 'max-width') {
    margin-top: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
    margin-bottom: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 44px margin top and margin bottom in desktop only
.u-my-double-wide {
  @include mq('large') {
    margin-top: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
    margin-bottom: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 44px padding top and padding bottom
.u-py-double {
  padding-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  padding-bottom: calc(
    2 * var(--emu-semantic-spacing-vertical-narrow)
  ) !important;
}

// 44px padding top and padding bottom in mobile only
.u-py-double-narrow {
  @include mq('1023px', 'max-width') {
    padding-top: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
    padding-bottom: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 44px padding top and padding bottom in desktop only
.u-py-double-wide {
  @include mq('large') {
    padding-top: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
    padding-bottom: calc(
      2 * var(--emu-semantic-spacing-vertical-narrow)
    ) !important;
  }
}

// 0px margin top in mobile till 1023px
.u-mt-none-narrow {
  @include mq('1023px', 'max-width') {
    margin-top: var(--emu-common-spacing-none) !important;
  }
}

// 0px padding top in mobile till 1023px
.u-pt-none-narrow {
  @include mq('1023px', 'max-width') {
    padding-top: var(--emu-common-spacing-none) !important;
  }
}

// 0px margin bottom in mobile till 1023px
.u-mb-none-narrow {
  @include mq('1023px', 'max-width') {
    margin-bottom: var(--emu-common-spacing-none) !important;
  }
}

// 0px padding bottom in mobile till 1023px
.u-pb-none-narrow {
  @include mq('1023px', 'max-width') {
    padding-bottom: var(--emu-common-spacing-none) !important;
  }
}

.u-my-none {
  margin-top: var(--emu-common-spacing-none) !important;
  margin-bottom: var(--emu-common-spacing-none) !important;
}

// 0px margin top and margin bottom in mobile till 1023px
.u-my-none-narrow {
  @include mq('1023px', 'max-width') {
    margin-top: var(--emu-common-spacing-none) !important;
    margin-bottom: var(--emu-common-spacing-none) !important;
  }
}

.u-py-none {
  padding-top: var(--emu-common-spacing-none) !important;
  padding-bottom: var(--emu-common-spacing-none) !important;
}

// 0px padding top and padding bottom in mobile till 1023px
.u-py-none-narrow {
  @include mq('1023px', 'max-width') {
    padding-top: var(--emu-common-spacing-none) !important;
    padding-bottom: var(--emu-common-spacing-none) !important;
  }
}

// 0px margin top in desktop starting from 1024px
.u-mt-none-wide {
  @include mq('large') {
    margin-top: var(--emu-common-spacing-none) !important;
  }
}

// 0px padding top in desktop starting from 1024px
.u-pt-none-wide {
  @include mq('large') {
    padding-top: var(--emu-common-spacing-none) !important;
  }
}

// 0px margin bottom in desktop starting from 1024px
.u-mb-none-wide {
  @include mq('large') {
    margin-bottom: var(--emu-common-spacing-none) !important;
  }
}

// 0px padding bottom in desktop starting from 1024px
.u-pb-none-wide {
  @include mq('large') {
    padding-bottom: var(--emu-common-spacing-none) !important;
  }
}

// 0px margin top and margin bottom in desktop starting from 1024px
.u-my-none-wide {
  @include mq('large') {
    margin-top: var(--emu-common-spacing-none) !important;
    margin-bottom: var(--emu-common-spacing-none) !important;
  }
}

// 0px padding top and padding bottom in desktop starting from 1024px
.u-py-none-wide {
  @include mq('large') {
    padding-top: var(--emu-common-spacing-none) !important;
    padding-bottom: var(--emu-common-spacing-none) !important;
  }
}

.u-mt-none {
  margin-top: var(--emu-common-spacing-none) !important;
}

.u-mb-none {
  margin-bottom: var(--emu-common-spacing-none) !important;
}

.u-pt-none {
  padding-top: var(--emu-common-spacing-none) !important;
}

.u-pb-none {
  padding-bottom: var(--emu-common-spacing-none) !important;
}

// 0px margin top and margin bottom in all devices
.u-my-none {
  margin-top: var(--emu-common-spacing-none) !important;
  margin-bottom: var(--emu-common-spacing-none) !important;
}

// 0px padding top and padding bottom in all devices
.u-py-none {
  padding-top: var(--emu-common-spacing-none) !important;
  padding-bottom: var(--emu-common-spacing-none) !important;
}

// no margin in all devices
.u-m-none {
  margin: var(--emu-common-spacing-none) !important;
}

// no padding in all devices
.u-p-none {
  padding: var(--emu-common-spacing-none) !important;
}

//no horizontal padding in all devices
.u-px-none {
  padding-left: var(--emu-common-spacing-none) !important;
  padding-right: var(--emu-common-spacing-none) !important;
}

//no horizontal padding  in mobile till 1023px
.u-px-none-narrow {
  @include mq('1023px', 'max-width') {
    padding-left: var(--emu-common-spacing-none) !important;
    padding-right: var(--emu-common-spacing-none) !important;
  }
}

//no horizontal padding in desktop starting from 1024px
.u-px-none-wide {
  @include mq('large') {
    padding-left: var(--emu-common-spacing-none) !important;
    padding-right: var(--emu-common-spacing-none) !important;
  }
}

.u-px-none {
  padding-left: var(--emu-common-spacing-none) !important;
  padding-right: var(--emu-common-spacing-none) !important;
}

.u-px-regular {
  padding-left: var(--emu-semantic-spacing-horizontal-narrow) !important;
  padding-right: var(--emu-semantic-spacing-horizontal-narrow) !important;
}

.u-px-regular-wide {
  @include mq('large') {
    padding-left: var(--emu-semantic-spacing-horizontal-narrow) !important;
    padding-right: var(--emu-semantic-spacing-horizontal-narrow) !important;
  }
}

.u-px-regular-narrow {
  @include mq('1023px', 'max-width') {
    padding-left: var(--emu-semantic-spacing-horizontal-narrow) !important;
    padding-right: var(--emu-semantic-spacing-horizontal-narrow) !important;
  }
}

.u-mx-auto,
.embed:has(.u-mx-auto),
div:has(.u-mx-auto) {
  margin-left: auto;
  margin-right: auto;
}

.image:has(.u-mx-auto) {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.u-separator {
  &--white {
    .emu-separator__rule {
      background-color: var(--emu-common-colors-white);
    }
  }

  &--black {
    .emu-separator__rule {
      background-color: var(--emu-common-colors-black);
    }
  }

  &--thin {
    .emu-separator__rule {
      height: var(--emu-common-border-width-thin);
    }
  }

  &--thick {
    .emu-separator__rule {
      height: var(--emu-common-border-width-thick);
    }
  }

  &--fixed-width {
    .emu-separator__rule {
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  &--fixed-width-large {
    .emu-separator__rule {
      max-width: 1120px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
